import { template as template_3f3bb692b03a430699b0a0b3333e6657 } from "@ember/template-compiler";
const WelcomeHeader = template_3f3bb692b03a430699b0a0b3333e6657(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
