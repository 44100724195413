import { template as template_ed50656dfa4045dea7e7c55c0535903b } from "@ember/template-compiler";
const FKLabel = template_ed50656dfa4045dea7e7c55c0535903b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
