import { template as template_ca8543e1eb8f48b9b003897550742d79 } from "@ember/template-compiler";
const SidebarSectionMessage = template_ca8543e1eb8f48b9b003897550742d79(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
